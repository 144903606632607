import React, { useState } from 'react';


import WelcomeGallery from '../components/index/Gallery';
import Menu from '../components/menu/Menu';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 


const GalleryPage = ()  => {

  return ( 
      <>  
		<Menu bgcolor='navbar navbar-expand-md navbar-dark fixed-top bg-blue'/>
		<WelcomeGallery />
      </>
  )
} 

export default GalleryPage