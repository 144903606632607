import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import * as welcomeStyles from './gallery.module.scss';

function MyComponent() {
  return (
    <>

<section   className={welcomeStyles.container}>
    <div class="container-fluid"> 
        <div class="row">
            <div class="col-sx-12" className={welcomeStyles.flexstart}>
                <h1 class={welcomeStyles.titleBlue}>Gallery</h1>
            </div>
         </div>
        <div class="row">
            <div class="col-sx-12">
                <SimpleReactLightbox>
                <SRLWrapper>
                    <a href="/static/1-ca657f1793fdfbb580a3c8281c8e38da.jpg">
                    <img height="200px" src="/static/1-ca657f1793fdfbb580a3c8281c8e38da.jpg" alt="Photo 1" />
                    </a>
                    <a href="/static/2-45834ba41e80c7ae9eb243130cff2c00.jpg">
                    <img height="200px" src="/static/2-45834ba41e80c7ae9eb243130cff2c00.jpg" alt="Photo 2" />
                    </a>
                    <a href="/static/3-515bb8f4ab8338c8008f7c7430976996.jpg">
                    <img height="200px" src="/static/3-515bb8f4ab8338c8008f7c7430976996.jpg" alt="Photo 3" />
                    </a>
                    <a href="/static/4-f3b3665111aee514bfabe1d60c5633cc.jpg">
                    <img height="200px" src="/static/4-f3b3665111aee514bfabe1d60c5633cc.jpg" alt="Photo 4" />
                    </a>
                    <a href="/static/5-d354726b1e8ab491fecb6109fc627534.jpg">
                    <img height="200px" src="/static/5-d354726b1e8ab491fecb6109fc627534.jpg" alt="Photo 5" />
                    </a>
                    <a href="/static/6-c9832ea80d8a25de191806309fb4af4f.jpg">
                    <img height="200px" src="/static/6-c9832ea80d8a25de191806309fb4af4f.jpg" alt="Photo 6" />
                    </a>
                    <a href="/static/7-17cc07e6635d71a31d242b95f973bf9b.jpg">
                    <img height="200px" src="/static/7-17cc07e6635d71a31d242b95f973bf9b.jpg" alt="Photo 7" />
                    </a>
                    <a href="/static/8-f7cbc99668f78a7103be61d9590eb6fa.jpg">
                    <img height="200px" src="/static/8-f7cbc99668f78a7103be61d9590eb6fa.jpg" alt="Photo 8" />
                    </a>
                    <a href="/static/9-9290c56f6b69abed7e2da7cb1b9ff6cf.png">
                    <img height="200px" src="/static/9-9290c56f6b69abed7e2da7cb1b9ff6cf.png" alt="Photo 9" />
                    </a>
                    <a href="/static/10-a387d4fd2e5961d39fa1ec26b2b555c8.jpg">
                    <img height="200px" src="/static/10-a387d4fd2e5961d39fa1ec26b2b555c8.jpg" alt="Photo 10" />
                    </a>
                    <a href="/static/11-548d423176e50f07592629ce79b4493e.jpg">
                    <img height="200px" src="/static/11-548d423176e50f07592629ce79b4493e.jpg" alt="Photo 11" />
                    </a>
                    <a href="/static/12-ea461e47ed5b53b25db8295c891ffb3b.jpg">
                    <img height="200px" src="/static/12-ea461e47ed5b53b25db8295c891ffb3b.jpg" alt="Photo 12" />
                    </a>
                    <a href="/static/13-07cefa89d7bf6d1adb36f6b81c8e4fb1.jpg">
                    <img height="200px" src="/static/13-07cefa89d7bf6d1adb36f6b81c8e4fb1.jpg" alt="Photo 13" />
                    </a>
                    <a href="/static/14-7e1dfdcf220ebd4f38281552afa2856e.jpg">
                    <img height="200px" src="/static/14-7e1dfdcf220ebd4f38281552afa2856e.jpg" alt="Photo 14" />
                    </a>

                    <a href="/static/15-e2ce05d1eae8554e189a0021013507e8.jpg">
                    <img height="200px" src="/static/15-e2ce05d1eae8554e189a0021013507e8.jpg" alt="Photo 15" />
                    </a>
                    <a href="/static/16-b8c279f2447b66b0850f44d6b4b5ea1d.jpeg">
                    <img height="200px" src="/static/16-b8c279f2447b66b0850f44d6b4b5ea1d.jpeg" alt="Photo 16" />
                    </a>
                    <a href="/static/17-04cbcb702c8d8baec3d2854074b4f78d.jpeg">
                    <img height="200px" src="/static/17-04cbcb702c8d8baec3d2854074b4f78d.jpeg" alt="Photo 17" />
                    </a>
                    <a href="/static/18-9e26db4648f1db0cc23b6786cbaacf44.jpeg">
                    <img height="200px" src="/static/18-9e26db4648f1db0cc23b6786cbaacf44.jpeg" alt="Photo 18" />
                    </a>
                    <a href="/static/19-1a9469bcff4a74061482a30c615c7f0d.jpeg">
                    <img height="200px" src="/static/19-1a9469bcff4a74061482a30c615c7f0d.jpeg" alt="Photo 19" />
                    </a>
                    <a href="/static/20-1f0fa23611b85369f24d2c8726275170.jpg">
                    <img height="200px" src="/static/20-1f0fa23611b85369f24d2c8726275170.jpg" alt="Photo 20" />
                    </a>
                    <a href="/static/21-527df6e5e40ce15165d81d0c3a5e5ca8.jpg">
                    <img height="200px" src="/static/21-527df6e5e40ce15165d81d0c3a5e5ca8.jpg" alt="Photo 21" />
                    </a>
                    <a href="/static/22-e26da45338c4d238b49aa0367d6e74c1.jpg">
                    <img height="200px" src="/static/22-e26da45338c4d238b49aa0367d6e74c1.jpg" alt="Photo 22" />
                    </a>
                    <a href="/static/23-43d8ccabce1aeb71415002a97d2dd71e.jpg">
                    <img height="200px" src="/static/23-43d8ccabce1aeb71415002a97d2dd71e.jpg" alt="Photo 23" />
                    </a>
                    <a href="/static/24-02625be94ac8725c42a90e0019148a90.jpg">
                    <img height="200px" src="/static/24-02625be94ac8725c42a90e0019148a90.jpg" alt="Photo 24" />
                    </a>
                    <a href="/static/25-c69d0da10faf0d25b2d8359a149360a9.jpg">
                    <img height="200px" src="/static/25-c69d0da10faf0d25b2d8359a149360a9.jpg" alt="Photo 25" />
                    </a>
                    <a href="/static/26-633f1b5e79be49554778722f5fae2d06.jpg">
                    <img height="200px" src="/static/26-633f1b5e79be49554778722f5fae2d06.jpg" alt="Photo 26" />
                    </a>
                    <a href="/static/27-90b97b05243e68d2cc301ffc06714227.jpg">
                    <img height="200px" src="/static/27-90b97b05243e68d2cc301ffc06714227.jpg" alt="Photo 27" />
                    </a>
                    <a href="/static/28-ed4bd1cf8d16d6ee9056e8c7b6b3bf70.jpg">
                    <img height="200px" src="/static/28-ed4bd1cf8d16d6ee9056e8c7b6b3bf70.jpg" alt="Photo 28" />
                    </a>
                    <a href="/static/29-724ca39e803a31a8a8a29f0b788bf244.jpg">
                    <img height="200px" src="/static/29-724ca39e803a31a8a8a29f0b788bf244.jpg" alt="Photo 29" />
                    </a>
                    <a href="/static/30-49274ed9c9c0b39734125604d8f887b5.jpg">
                    <img height="200px" src="/static/30-49274ed9c9c0b39734125604d8f887b5.jpg" alt="Photo 30" />
                    </a>
                    <a href="/static/31-6df80c5904a5bfbd54fbe32847fe8b1f.jpg">
                    <img height="200px" src="/static/31-6df80c5904a5bfbd54fbe32847fe8b1f.jpg" alt="Photo 31" />
                    </a>
                    <a href="/static/32-cf03d5eeabd07bf854ecbf275bf2fee9.jpg">
                    <img height="200px" src="/static/32-cf03d5eeabd07bf854ecbf275bf2fee9.jpg" alt="Photo 32" />
                    </a>
                    <a href="/static/33-0ad3b72b8fc0afa9edf189785fd4e3e6.jpeg">
                    <img height="200px" src="/static/33-0ad3b72b8fc0afa9edf189785fd4e3e6.jpeg" alt="Photo 33" />
                    </a>
                    <a href="/static/34-50603aabc9f25917704761df10b3cc42.jpg">
                    <img height="200px" src="/static/34-50603aabc9f25917704761df10b3cc42.jpg" alt="Photo 34" />
                    </a>
                    <a href="/static/35-516cb1386d9066fdee9f419ad54d2d38.jpeg">
                    <img height="200px" src="/static/35-516cb1386d9066fdee9f419ad54d2d38.jpeg" alt="Photo 35" />
                    </a>
                    <a href="/static/36-f5bd09208e6a91b11b81c88d5f158bd5.jpeg">
                    <img height="200px" src="/static/36-f5bd09208e6a91b11b81c88d5f158bd5.jpeg" alt="Photo 36" />
                    </a>
                    <a href="/static/37-fcfc5fd4d080956533642c9b301cb2c2.jpg">
                    <img height="200px" src="/static/37-fcfc5fd4d080956533642c9b301cb2c2.jpg" alt="Photo 37" />
                    </a>
                    <a href="/static/38-e15f4954ba761a58d001e1d4bb3480a2.jpeg">
                    <img height="200px" src="/static/38-e15f4954ba761a58d001e1d4bb3480a2.jpeg" alt="Photo 38" />
                    </a>
                    <a href="/static/39-39670878bd0bf9d5278000ca69cf4109.jpeg">
                    <img height="200px" src="/static/39-39670878bd0bf9d5278000ca69cf4109.jpeg" alt="Photo 39" />
                    </a>
                    <a href="/static/40-3990369dc0a54df75c85bf57a6d5f033.jpg">
                    <img height="200px" src="/static/40-3990369dc0a54df75c85bf57a6d5f033.jpg" alt="Photo 40" />
                    </a>
                    <a href="/static/41-df9710a14d215645b3089052b406ea32.jpg">
                    <img height="200px" src="/static/41-df9710a14d215645b3089052b406ea32.jpg" alt="Photo 41" />
                    </a>
                    <a href="/static/42-f71d6ba3241e93f74982977beeadce52.jpeg">
                    <img height="200px" src="/static/42-f71d6ba3241e93f74982977beeadce52.jpeg" alt="Photo 42" />
                    </a>
                    <a href="/static/43-3ccb1c66dee401090f73db647b939961.jpg">
                    <img height="200px" src="/static/43-3ccb1c66dee401090f73db647b939961.jpg" alt="Photo 43" />
                    </a>
                    <a href="/static/44-2c88e65bd9e79c1567558a82c14d4e5c.jpeg">
                    <img height="200px" src="/static/44-2c88e65bd9e79c1567558a82c14d4e5c.jpeg" alt="Photo 44" />
                    </a>
                    <a href="/static/45-6234ead25e59bdeb80dab1eceba0f690.jpg">
                    <img height="200px" src="/static/45-6234ead25e59bdeb80dab1eceba0f690.jpg" alt="Photo 45" />
                    </a>


                    <a href="/static/46-5e91a0a512e75a2c978d302d27f2db0c.jpg">
                    <img height="200px" src="/static/46-5e91a0a512e75a2c978d302d27f2db0c.jpg" alt="Photo 46" />
                    </a>
                    <a href="/static/47-2cd7814870202fedca15560f84be3cb0.jpg">
                    <img height="200px" src="/static/47-2cd7814870202fedca15560f84be3cb0.jpg" alt="Photo 47" />
                    </a>
                    <a href="/static/48-1bc2749fd8ac7b1b80dd2f60c99204a7.jpg">
                    <img height="200px" src="/static/48-1bc2749fd8ac7b1b80dd2f60c99204a7.jpg" alt="Photo 48" />
                    </a>
                    <a href="/static/49-d209f5b5a885d603bbd2ec8682d8850b.jpg">
                    <img height="200px" src="/static/49-d209f5b5a885d603bbd2ec8682d8850b.jpg" alt="Photo 49" />
                    </a>
                    <a href="/static/50-0fceba0755b52d1f05508a2453270652.jpg">
                    <img height="200px" src="/static/50-0fceba0755b52d1f05508a2453270652.jpg" alt="Photo 50" />
                    </a>
                </SRLWrapper>
                </SimpleReactLightbox>
            </div>
        </div>
    </div>
 
    </section>
    </>
  );
}

export default MyComponent;